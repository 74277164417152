import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const Wrapper = styled.div``
const Image = styled.img`
  margin-left: auto;

  margin-right: auto;

  margin-top: auto;
  margin-bottom: auto;

  height: 100%;
  width: 100%;
`
const ZoneImage = ({ data, ...props }) => (
  <Wrapper {...props}>
    <Image src={data} />
  </Wrapper>
)
ZoneImage.propTypes = {
  data: PropTypes.string,
}
ZoneImage.defaultProps = {
  data: 'https://avalanche.report/albina_files/2019-11-29/164528de-b0d5-4442-89fe-186dea86f1ad.jpg',
}
export default ZoneImage
