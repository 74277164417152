import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import DangerField from './components/DangerField'
import ZoneImage from './components/ZoneImage'
import Description from './components/Description'

const Wrapper = styled.div``

const SuperDiv = styled.div`
  display: flex;

  @media (max-width: ${props => props.theme.mobile.breakPoint + 'px'}) {
    display: blocK;
  }
`
const StyledDiv = styled.div`
  width: 27%;
  margin-left: 5px;
  @media (max-width: ${props => props.theme.tablet.breakPoint + 'px'}) {
    width: 35%;
  }
  @media (max-width: ${props => props.theme.mobile.breakPoint + 'px'}) {
    margin-left: auto;
    margin-right: auto;
    width: 80%;
  }
`
const StyledDescription = styled(Description)`
  width: 65%;
  @media (max-width: ${props => props.theme.tablet.breakPoint + 'px'}) {
    width: 60%;
    margin-left: auto;
    margin-right: auto;
  }
  @media (max-width: ${props => props.theme.mobile.breakPoint + 'px'}) {
    width: 100%;
  }
`

const DangerLevel = ({ data, ...props }) => (
  <Wrapper {...props}>
    <SuperDiv>
      <StyledDiv>
        <ZoneImage data={data.zoneImage}></ZoneImage>

        <DangerField data={data.dangerField}></DangerField>
      </StyledDiv>

      <StyledDescription data={data.description}></StyledDescription>
    </SuperDiv>
  </Wrapper>
)
DangerLevel.propTypes = {
  data: PropTypes.shape({
    zoneImage: ZoneImage.propTypes,
    dangerField: DangerField.propTypes,
    description: Description.propTypes,
  }),
}
DangerLevel.defaultProps = {
  data: {
    zoneImage: ZoneImage.defaultProps.data,
    dangerField: DangerField.defaultProps,
    description: Description.defaultProps.data,
  },
}
export default DangerLevel
