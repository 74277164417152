import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Text from '../../../../../../../../../../../../components/Text'
import Title from '../../../../../../../../../../../../components/Title'
import DangerTendency from '../DangerField/components/DangerTendency'

const Wrapper = styled.div`
  width: 80%;

  margin-left: auto;

  margin-right: auto;
`

const StyledTitle = styled(Title)``
const HiLight = styled(Text)`
  font-weight: bold;
`
const TextP = styled(Text)``

const Description = ({ data, ...props }) => (
  <Wrapper {...props}>
    {data.danger == 1 && <StyledTitle>Gefahrenstufe 1 - Gering</StyledTitle>}
    {data.danger == 2 && <StyledTitle>Gefahrenstufe 2 - Mäßig</StyledTitle>}
    {data.danger == 3 && <StyledTitle>Gefahrenstufe 3 - Erheblich</StyledTitle>}
    {data.danger == 4 && <StyledTitle>Gefahrenstufe 4 - Groß</StyledTitle>}
    {data.danger == 5 && <StyledTitle>Gefahrenstufe 5 - Sehr Groß</StyledTitle>}

    <HiLight>{data.title}</HiLight>
    <br></br>
    <TextP>{data.description}</TextP>
    <br></br>
  </Wrapper>
)
Description.propTypes = {
  data: PropTypes.shape({
    title: PropTypes.string,
    text: PropTypes.string,
    danger: PropTypes.number,
    dangerTendency: PropTypes.shape({
      tendency: PropTypes.oneOf([0, -1, 1]),
      dateNext: PropTypes.string,
    }),
  }),
}
Description.defaultProps = {
  data: {
    title: 'Frischen Triebschnee beachten',
    text:
      'Mit teils starkem Wind aus nordwestlichen Richtungen entstehen an allen Expositionen teils störanfällige Triebschneeansammlungen. Die frischen und älteren Triebschneeansammlungen müssen vorsichtig beurteilt werden. Vorsicht in Rinnen, Mulden und hinter Geländekanten. In hohen Lagen und im Hochgebirge sind die Gefahrenstellen häufiger. Es sind einzelne Gleitschneelawinen möglich, vor allem solche mittlerer Grösse, besonders in den schneereichen Gebieten unterhalb von rund 2600 m. Zonen mit Gleitschneerissen sollten möglichst gemieden werden.',
    danger: 1,
    dangerTendency: {
      tendency: 0,
      dateNext: 'am Samstag den 30.11.2019',
    },
  },
}
export default Description
