import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Levels1_1 from '../../images/levels_1_1.svg'
import Levels1_2 from '../../images/levels_1_2.svg'
import Levels1_3 from '../../images/levels_1_3.svg'
import Levels1_4 from '../../images/levels_1_4.svg'
import Levels1_5 from '../../images/levels_1_5.svg'

import Levels2_1 from '../../images/levels_2_1.svg'
import Levels2_2 from '../../images/levels_2_2.svg'
import Levels2_3 from '../../images/levels_2_3.svg'
import Levels2_4 from '../../images/levels_2_4.svg'
import Levels2_5 from '../../images/levels_2_5.svg'
import Levels3_1 from '../../images/levels_3_1.svg'
import Levels3_2 from '../../images/levels_3_2.svg'
import Levels3_3 from '../../images/levels_3_3.svg'
import Levels3_4 from '../../images/levels_3_4.svg'
import Levels3_5 from '../../images/levels_3_5.svg'
import Levels4_1 from '../../images/levels_4_1.svg'
import Levels4_2 from '../../images/levels_4_2.svg'
import Levels4_3 from '../../images/levels_4_3.svg'
import Levels4_4 from '../../images/levels_4_4.svg'
import Levels4_5 from '../../images/levels_4_5.svg'
import Levels5_1 from '../../images/levels_5_1.svg'
import Levels5_2 from '../../images/levels_5_2.svg'
import Levels5_3 from '../../images/levels_5_3.svg'
import Levels5_4 from '../../images/levels_5_4.svg'
import Levels5_5 from '../../images/levels_5_5.svg'

const LevelMap = code => {
  const map = {
    levels1_1: Levels1_1,
    levels1_2: Levels1_2,
    levels1_3: Levels1_3,
    levels1_4: Levels1_4,
    levels1_5: Levels1_5,
    levels2_1: Levels2_1,
    levels2_2: Levels2_2,
    levels2_3: Levels2_3,
    levels2_4: Levels2_4,
    levels2_5: Levels2_5,
    levels3_1: Levels3_1,
    levels3_2: Levels3_2,
    levels3_3: Levels3_3,
    levels3_4: Levels3_4,
    levels3_5: Levels3_5,
    levels4_1: Levels4_1,
    levels4_2: Levels4_2,
    levels4_3: Levels4_3,
    levels4_4: Levels4_4,
    levels4_5: Levels4_5,
    levels5_1: Levels5_1,
    levels5_2: Levels5_2,
    levels5_3: Levels5_3,
    levels5_4: Levels5_4,
    levels5_5: Levels5_5,
  }
  return map[code]
}

LevelMap.propTypes = {}
LevelMap.defaultProps = {}
export default LevelMap
