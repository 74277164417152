import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Text from '../../../../../../../../../../../../components/Text'

const Wrapper = styled.div``
const Title = styled.h2``

const TendencyDesc = styled(Text)``
const SnowTendency = ({ data, ...props }) => (
  <Wrapper {...props}>
    <Title>Tendenz</Title>
    <TendencyDesc>{data}</TendencyDesc>
  </Wrapper>
)
SnowTendency.propTypes = {
  data: PropTypes.string,
}
SnowTendency.defaultProps = {
  data: 'Vorsicht vor frischem Triebschnee.',
}

export default SnowTendency
