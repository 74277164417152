import React from 'react'
import styled from 'styled-components'
const Wrapper = styled.div``

const SvgDangerExpositionR = props => (
  <Wrapper {...props}>
    <svg viewBox="0 0 100 100">
      <polygon
        className="w"
        points="17.9666748046875,37.56666564941406 17.9666748046875,61.26667022705078 46.4666748046875,49.46666717529297 "
      />
      <polygon
        className="sw"
        points="19.066680908203125,64.06666564941406 35.866668701171875,80.76666259765625 47.666656494140625,52.16667175292969 "
      />
      <polygon
        className="s"
        points="38.566680908203125,81.9666748046875 62.26666259765625,81.9666748046875 50.4666748046875,53.366668701171875 "
      />
      <polygon
        className="se"
        points="65.06668090820312,80.76666259765625 81.76666259765625,64.06666564941406 53.166656494140625,52.16667175292969 "
      />
      <polygon
        className="e"
        points="82.9666748046875,61.26667022705078 82.9666748046875,37.56666564941406 54.366668701171875,49.46666717529297 "
      />
      <polygon
        className="ne"
        points="81.76666259765625,34.866668701171875 65.06668090820312,18.066665649414062 53.166656494140625,46.66667175292969 "
      />
      <polygon
        className="n"
        points="62.26666259765625,16.96666717529297 38.566680908203125,16.96666717529297 50.4666748046875,45.46666717529297 "
      />
      <polygon
        className="nw"
        points="35.866668701171875,18.066665649414062 19.066680908203125,34.866668701171875 47.666656494140625,46.66667175292969 "
      />
      <text
        fontFamily='"Fira Sans", sans-serif'
        fontSize={24}
        strokeDasharray="null"
        strokeLinecap="null"
        strokeLinejoin="null"
        strokeWidth={0}
        textAnchor="middle"
        transform="matrix(.73228 0 0 .63066 5.512 21.732)"
        fill="#000000"
        x={4.216}
        y={53.58}
      >
        {'W'}
      </text>
      <text
        fontFamily='"Fira Sans", sans-serif'
        fontSize={24}
        strokeDasharray="null"
        strokeLinecap="null"
        strokeLinejoin="null"
        strokeWidth={0}
        textAnchor="middle"
        transform="matrix(.88266 0 0 .69908 4.25 28.715)"
        fill="#000000"
        x={53.079}
        y={95.738}
      >
        {'S'}
      </text>
      <text
        fontFamily='"Fira Sans", sans-serif'
        fontSize={24}
        strokeDasharray="null"
        strokeLinecap="null"
        strokeLinejoin="null"
        strokeWidth={0}
        textAnchor="middle"
        transform="matrix(.79542 0 0 .73629 -15.255 21.687)"
        fill="#000000"
        x={131.822}
        y={47.137}
      >
        {'E'}
      </text>
      <text
        fontFamily='"Fira Sans", sans-serif'
        fontSize={24}
        strokeDasharray="null"
        strokeLinecap="null"
        strokeLinejoin="null"
        strokeWidth={0}
        textAnchor="middle"
        transform="matrix(.8298 0 0 .73419 -7.018 11.46)"
        fill="#000000"
        x={69.405}
        y={5.328}
      >
        {'N'}
      </text>
    </svg>
  </Wrapper>
)

export default SvgDangerExpositionR
