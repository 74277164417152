import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import TitleDescriptionForecast from '../../../../components/TitleDescriptionForecast'
import TitleDescriptionPage from '../../../../../../../../components/TitleDescriptionPage'
import translate from '../../../../../../../../modules/translate'
import ExpositionRose from './components/ExpositionRose'
import Title from '../../../../../../../../components/Title'

const Wrapper = styled.div``

const StyledTitleDescriptionPage = styled(TitleDescriptionPage)`
  margin-bottom: 17px;
`

const Data = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
`

const StyledTitle = styled(Title)`
  font-size: ${(props) => props.theme.fontSize.text};
  @media (max-width: ${(props) => props.theme.tablet.breakPoint + 'px'}) {
    font-size: ${(props) => props.theme.tablet.fontSize.text};
  }
  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    font-size: ${(props) => props.theme.mobile.fontSize.text};
  }
  color: ${(props) => props.theme.color.stol};
  margin: 36px 0 0;
`

const dangerParse = (dangerCode) => {
  const map = {
    1: 'gering',
    2: 'mäßig',
    3: 'erheblich',
    4: 'groß',
    5: 'sehr groß',
  }

  return map[dangerCode] || 0
}

const DangerZoneForecastAvalanche = ({ expositionRose, ...props }) => {
  return (
    <Wrapper {...props}>
      <TitleDescriptionForecast>
        <strong>{translate('weather_avalanche_danger_zone_header')}</strong>
      </TitleDescriptionForecast>
      <StyledTitleDescriptionPage>{translate('weather_avalanche_danger_zone_color')}</StyledTitleDescriptionPage>
      <Data>
        {expositionRose.map((exposition, index) => {
          let dangerCodeName = dangerParse(exposition.dangerCode)
          return (
            <div key={'expositionRose' + index}>
              <ExpositionRose aspects={exposition.aspects} />
              <StyledTitle>{dangerCodeName}</StyledTitle>
              <TitleDescriptionPage>{exposition.altitude}</TitleDescriptionPage>
            </div>
          )
        })}
      </Data>
    </Wrapper>
  )
}

DangerZoneForecastAvalanche.propTypes = {
  expositionRose: PropTypes.arrayOf(
    PropTypes.shape({
      dangerCode: PropTypes.number,
      directionCode: PropTypes.string,
      altitude: PropTypes.string,
    }),
  ),
}

export default DangerZoneForecastAvalanche
