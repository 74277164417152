import styled from 'styled-components'
import PropTypes from 'prop-types'

const TitleDescriptionPage = styled.h3`
  font-family: ${(props) => props.theme.font.primary};
  font-size: ${(props) => props.fontSize};
  @media (max-width: ${(props) => props.theme.tablet.breakPoint + 'px'}) {
    font-size: ${(props) => props.theme.tablet.fontSize.base};
  }
  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    font-size: ${(props) => props.theme.mobile.fontSize.base};
  }
  font-weight: ${(props) => props.theme.fontWeight.regular};
  color: ${(props) => (props.inverted ? props.theme.color.textInverted : props.theme.color.textSecondary)};
  margin: 0;
`

TitleDescriptionPage.propTypes = {
  children: PropTypes.node,
}

export default TitleDescriptionPage
