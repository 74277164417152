import styled from 'styled-components'

const TitleDescriptionForecast = styled.div`
  font-family: ${props => props.theme.font.primary};
  font-size: ${props => props.theme.fontSize.title3};
  @media (max-width: ${props => props.theme.tablet.breakPoint + 'px'}) {
    font-size: ${props => props.theme.tablet.fontSize.title3};
  }
  @media (max-width: ${props => props.theme.mobile.breakPoint + 'px'}) {
    font-size: ${props => props.theme.mobile.fontSize.title3};
  }
  font-weight: ${props => props.theme.fontWeight.regular};
  margin-bottom: 0.2em;
`

export default TitleDescriptionForecast
