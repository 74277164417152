import moment from 'moment'
import translate from '../../../../../../modules/translate'

const DATE_FORMAT = 'LL'
const TIME_FORMAT = 'k:mm'
const TOMORROW_WEATHER_TIME = moment('11:00', TIME_FORMAT)

const subtitleParser = (date, isTomorrow, hasData, weatherType = 'forecast') => {
  if (hasData) {
    return translate('weather_' + weatherType + '_header', {
      type: weatherType,
      day: isTomorrow ? translate('tomorrow') : translate('today'),
      date: moment(date)
        .locale('de')
        .format(DATE_FORMAT),
    })
  }

  if (isTomorrow && TOMORROW_WEATHER_TIME.diff(moment()) > 0) {
    return translate('weather_' + weatherType + '_no_data_tomorrow', { type: weatherType })
  }

  return translate('weather_' + weatherType + '_no_data', { type: weatherType })
}

export default subtitleParser
