import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Text from '../../../../../../../../../../../../../../components/Text'
import LevelMap from './component/LevelMap'

const Wrapper = styled.div`
  display: flex;
  width: 200px;
  margin-left: auto;

  margin-right: auto;
`
const Image = styled.img`
  margin-left: auto;
  margin-right: auto;
  margin-top: auto;
  margin-bottom: auto;
  height: 100%;
  width: 100%;

  max-width: 80px;
`
const TreeLine = styled(Text)`
  margin-top: auto;
  margin-bottom: auto;
`
const DangerLevelImage = ({ data, ...props }) => (
  <Wrapper {...props}>
    <Image src={LevelMap('levels' + data.level2 + '_' + data.level1)}></Image>
    {data.level1 != data.level2 && <TreeLine>{data.altitude}</TreeLine>}
  </Wrapper>
)
DangerLevelImage.propTypes = {
  data: PropTypes.shape({
    level1: PropTypes.oneOf([1, 2, 3, 4, 5]),
    level2: PropTypes.oneOf([1, 2, 3, 4, 5]),
  }),
}
DangerLevelImage.defaultProps = {
  data: {
    level1: 1,
    level2: 1,
    altitude: 'Waldgrenze',
  },
}
export default DangerLevelImage
