import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import DangerLevelImage from './components/DangerLevelImage'
import DangerSnow from './components/DangerSnow'
import DangerTendency from './components/DangerTendency'

const Wrapper = styled.div``
const SuperDiv = styled.div`
  display: flex;
`

const DangerField = ({ data, ...props }) => (
  <Wrapper>
    <SuperDiv>
      <DangerLevelImage data={data.dangerLevel}></DangerLevelImage>
    </SuperDiv>
  </Wrapper>
)

DangerField.propTypes = {
  data: PropTypes.shape({
    dangerLevel: DangerLevelImage.propTypes,
    dangerSnow: DangerSnow.propTypes,
    dangerTendency: DangerTendency.propTypes,
  }),
}
DangerField.defaultProps = {
  data: {
    dangerLevel: DangerLevelImage.defaultProps.data,
    dangerSnow: DangerSnow.defaultProps.data,
    dangerTendency: DangerTendency.defaultProps.data,
  },
}
export default DangerField
