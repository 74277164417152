import styled from 'styled-components'
import Title from '../../../../../../components/Title'

const TitleForecast = styled(Title)`
  font-size: ${(props) => props.theme.fontSize.title1};
  @media (max-width: ${(props) => props.theme.tablet.breakPoint + 'px'}) {
    font-size: ${(props) => props.theme.tablet.fontSize.title1};
  }
  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    font-size: ${(props) => props.theme.mobile.fontSize.title1};
  }
  font-weight: ${(props) => props.theme.fontWeight.bold};
  color: ${(props) => props.theme.color.stol};
  margin-top: 0;
`

TitleForecast.defaultProps = {
  tag: 'h2',
}

export default TitleForecast
