import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Chip from '@mui/material/Chip'

const Wrapper = styled.div`
  display: flex;
`

const Title = styled.h3``

const Label = styled(Chip)`
  margin-top: auto;
  margin-bottom: auto;
  margin-left: 10px;
`

const SnowDangerPattern = ({ data, ...props }) => (
  <Wrapper {...props}>
    <Title>Gefahrenmuster</Title>

    {data.map((chip) => {
      return <Label label={chip.chip} />
    })}
  </Wrapper>
)
SnowDangerPattern.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      chip: PropTypes.string,
    }),
  ),
}
SnowDangerPattern.defaultProps = {
  data: [{ chip: 'gm 6: lockerer schnee und wind' }, { chip: 'gm 2: gleitschnee' }],
}
export default SnowDangerPattern
