import React from 'react'
import subtitleParser from '../../modules/subtitle-parser'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import ScaleMapForecastAvalanche from './components/ScaleMapForeCastAvalanche'
import DangerZoneForecastAvalanche from './components/DangerZoneForecastAvalanche'
import SnowProblem from './components/SnowProblem'
import styledContainerQuery from 'styled-container-query'
import Forecast from '../..'
import Image from '../../../../../../components/Image'
import DangerReport from './components/DangerReport'

const StyledForecast = styled(Forecast)`
  padding-bottom: 1em;
`
const StyledDangerZoneForecastAvalanche = styled(DangerZoneForecastAvalanche)`
  width: ${(props) => props.theme.sizes.col6};
  position: relative;

  &:after {
    content: ' ';
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    right: -${(props) => props.theme.sizes.gapColHalf};
    border-width: 0;
    border-color: ${(props) => props.theme.color.textTertiary};
    border-right-width: 1px;
    border-style: solid;
  }
  @media (max-width: ${(props) => props.theme.tablet.breakPoint + 'px'}) {
    width: ${(props) => props.theme.tablet.sizes.col6};
    &:after {
      right: -${(props) => props.theme.tablet.sizes.gapColHalf};
    }
  }
  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    width: ${(props) => props.theme.mobile.sizes.col6};
    &:after {
      right: -${(props) => props.theme.mobile.sizes.gapColHalf};
    }
  }
`

const StyledSnowProblem = styled(SnowProblem)`
  width: ${(props) => props.theme.sizes.col6};
  @media (max-width: ${(props) => props.theme.tablet.breakPoint + 'px'}) {
    width: ${(props) => props.theme.tablet.sizes.col6};
  }
  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    width: ${(props) => props.theme.mobile.sizes.col6};
  }
  @-moz-document url-prefix() {
    position: absolute;
    right: 0;
  }
`

const GraphicsWrapper = styled.div``
const Graphic = styled.div``

const BelowMap = styledContainerQuery.div`
  margin: 57px 0 64px;
  display: flex;
  justify-content: space-between;

  &:container(max-width: 500px){
    flex-wrap: wrap;

    ${StyledDangerZoneForecastAvalanche} {
      position: relative;
      width: ${(props) => props.theme.sizes.col12};

      &:after {
        bottom: ${(props) => props.theme.sizes.gapColHalf};
        left: 0;
        right: 0;
        top: auto;
        border-right-width: 0px;
        border-bottom-width: 1px;
      }
    }

    ${StyledSnowProblem} {
      margin-top: 18px;
      width 100%;
    }
`

const StyledDangerReport = styled(DangerReport)`
  margin-bottom: 20px;
`

const StyledMapImage = styled(Image)`
  max-width: 100%;
  margin: ${(props) => props.theme.sizes.gapVerticalHalf} 0 ${(props) => props.theme.sizes.gapVertical} 0;

  img {
    z-index: 0 !important;
  }
`

const ForecastAvalanche = ({ data, adComponent, ...props }) => {
  let hasData = true
  let isTomorrow = false

  if (!data) throw new Error('test')
  const { image, publicationDate, title, image_afternoon } = data
  const subtitle = subtitleParser(publicationDate, isTomorrow, hasData, 'avalanche')
  const dateValue = new Date(data.publicationDate)
  dateValue.setDate(dateValue.getDate() + 1)
  let today = dateValue.toLocaleDateString('de-DE', { weekday: 'long' })
  const currentMonth = dateValue.toLocaleString('de-DE', { month: 'long' })
  let time_string = ''
  let day = dateValue.getDate()
  let month = dateValue.getMonth() + 1
  if (day < 10) {
    day = '0' + day
  }
  if (month < 10) {
    month = '0' + month
  }

  let afternoon_arr = []
  data.dangerReport.forEach(function (i) {
    if (i.afternoon) {
      afternoon_arr.push(i)
    }
  })
  if (image_afternoon && image.localeCompare(image_afternoon) != 0 && afternoon_arr.length != 0) {
    time_string = ' - Vormittag'
  }

  return (
    <div>
      <StyledForecast
        subtitle={
          'Lawinenlagebericht am ' +
          today +
          ', ' +
          day +
          '. ' +
          currentMonth +
          ' ' +
          dateValue.getFullYear() +
          time_string
        }
        title={title}
        {...(hasData && {
          elementBelowTitle: (
            <GraphicsWrapper>
              <StyledMapImage src={image} />
              <ScaleMapForecastAvalanche />
            </GraphicsWrapper>
          ),
        })}
        {...props}
      />
      {data.dangerReport.map((report) => {
        if (!report.afternoon) {
          return <StyledDangerReport data={report} />
        }
      })}
      {adComponent}

      {image_afternoon && image.localeCompare(image_afternoon) != 0 && afternoon_arr.length != 0 && (
        <div>
          <StyledForecast
            subtitle={
              'Lawinenlagebericht am ' +
              today +
              ', ' +
              day +
              '. ' +
              currentMonth +
              ' ' +
              dateValue.getFullYear() +
              ' - Nachmittag'
            }
            title={title}
            {...(hasData && {
              elementBelowTitle: (
                <GraphicsWrapper>
                  <StyledMapImage src={image_afternoon} />
                  <ScaleMapForecastAvalanche />
                </GraphicsWrapper>
              ),
            })}
            {...props}
          />

          {afternoon_arr.map((report) => {
            return <StyledDangerReport data={report} />
          })}
        </div>
      )}
    </div>
  )
}

ForecastAvalanche.propTypes = {
  date: PropTypes.string,
  lastUpdate: PropTypes.string,
  title: PropTypes.string,
  message: PropTypes.object,
  state: PropTypes.string,
  dangerReport: DangerReport.propTypes,
}

export default ForecastAvalanche
export { StyledDangerZoneForecastAvalanche as StyledDangerZoneForecastAvalanche0ForecastAvalanche }
