import React, { Component } from 'react'
import Forecast, { Forecast0ContainerArticle, Forecast0Wrapper } from '../..'
import TextSkeleton from '../../../../../../components/Text/views/TextSkeleton'
import styled, { withTheme } from 'styled-components'
import propsType from 'prop-types'

const StyledTextSkeleton = styled(TextSkeleton)`
  margin-top: 0.5em;
  margin-bottom: 0.2em;
`

class ForecastSkeleton extends Component {
  render = () => {
    const { elementBelowTitle, textBlocks, reports, ...props } = this.props
    return (
      <Forecast0Wrapper {...props}>
        <Forecast0ContainerArticle>
          <StyledTextSkeleton lastWidth="60%" />
          {elementBelowTitle}
          {textBlocks}
          {reports}
        </Forecast0ContainerArticle>
      </Forecast0Wrapper>
    )
  }
}

ForecastSkeleton.propTypes = {
  elementBelowTitle: Forecast.propTypes.elementEnd,
  textBlocks: propsType.node,
}

export default withTheme(ForecastSkeleton)
