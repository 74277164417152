import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import DangerExpositionRose from './images/DangerExpositionR'

const Wrapper = styled.div`
  position: relative;
`

let StyledDangerExpositionRose = styled(DangerExpositionRose)`
  display: block;
  fill: gray;
  margin-left: auto;
  margin-right: auto;

  .n {
    fill: ${props => (props.roseIsDark.n ? '#626684' : props.theme.color.textTertiary)};
  }
  .nw {
    fill: ${props => (props.roseIsDark.nw ? '#626684' : props.theme.color.textTertiary)};
  }
  .ne {
    fill: ${props => (props.roseIsDark.ne ? '#626684' : props.theme.color.textTertiary)};
  }
  .e {
    fill: ${props => (props.roseIsDark.e ? '#626684' : props.theme.color.textTertiary)};
  }
  .se {
    fill: ${props => (props.roseIsDark.se ? '#626684' : props.theme.color.textTertiary)};
  }
  .sw {
    fill: ${props => (props.roseIsDark.sw ? '#626684' : props.theme.color.textTertiary)};
  }
  .s {
    fill: ${props => (props.roseIsDark.s ? '#626684' : props.theme.color.textTertiary)};
  }
  .w {
    fill: ${props => (props.roseIsDark.w ? '#626684' : props.theme.color.textTertiary)};
  }
`

const ExpositionRose = ({ aspects = [], ...props }) => {
  let roseIsDark = {}
  if (aspects && aspects.length > 0) {
    aspects.forEach(function(aspect) {
      roseIsDark[aspect] = true
    })
  }
  return (
    <Wrapper {...props}>
      <StyledDangerExpositionRose roseIsDark={roseIsDark} />
    </Wrapper>
  )
}

ExpositionRose.propTypes = {
  aspects: PropTypes.array,
}

export default ExpositionRose
