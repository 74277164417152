import React from 'react'
import ForecastSkeleton from '../../../ForecastSkeleton'
import TextSkeleton from '../../../../../../../../components/Text/views/TextSkeleton'
import styled from 'styled-components'
import skeletonFade from '../../../../../../../../modules/mixins/skeleton-fade'
import ScaleMapForecastAvalanche from '../../components/ScaleMapForeCastAvalanche'

const SuperDiv = styled.div`
  display: flex;
  margin-top: 50px;
  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    display: blocK;
  }
`

const TextDiv = styled.div`
  width: 55%;
  margin-left: auto;
  margin-right: auto;
  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    width: 100%;
  }
`

const StyledDiv = styled.div`
  ${skeletonFade};
  width: 30%;
  height: 280px;
  background-color: lightgrey;

  margin-left: auto;
  margin-right: auto;
  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    width: 70%;
  }
`

const ImageSkeleton = styled.div`
  width: 100%;
  padding-top: 66.66%;
  background-color: ${(props) => props.theme.color.skeletonScreen};
  ${skeletonFade}
`

const ForecastAvalancheSkeleton = ({ ...props }) => (
  <ForecastSkeleton
    elementBelowTitle={
      <div>
        <TextSkeleton lastWidth="280px" />
        <ImageSkeleton />
        <ScaleMapForecastAvalanche loading />
      </div>
    }
    textBlocks={<br></br>}
    reports={
      <SuperDiv>
        <StyledDiv></StyledDiv>

        <TextDiv>
          <TextSkeleton fontSize="24px" lastWidth="350px" lineWidth="380px" lineHeight={2} />
          <TextSkeleton lines={16} lastWidth="60%" />
        </TextDiv>
      </SuperDiv>
    }
    {...props}
  />
)

export default ForecastAvalancheSkeleton
