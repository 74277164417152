import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Text from '../../../../../../../../../../../../../../components/Text'

const Wrapper = styled.div``
const Tendency = styled(Text)`
  font-weight: bold;
`
const Date = styled(Text)``

/*TODO: Add  ArrowImage, and fix Day-Name*/
const DangerTendency = ({ data, ...props }) => (
  <Wrapper {...props}>
    {data.tendency == 0 && <Tendency>Tendenz: Lawinengefahr bleibt gleich</Tendency>}
    {data.tendency == 1 && <Tendency>Tendenz: Lawinengefahr nimmt zu</Tendency>}
    {data.tendency == -1 && <Tendency>Tendenz: Lawinengefahr nimmt ab</Tendency>}

    <Date>{data.dateNext}</Date>
  </Wrapper>
)
DangerTendency.propTypes = {
  data: PropTypes.shape({
    tendency: PropTypes.oneOf([0, -1, 1]),
    dateNext: PropTypes.string,
  }),
}
DangerTendency.defaultProps = {
  data: {
    tendency: 0,
    dateNext: '29.11.2019',
  },
}

export default DangerTendency
