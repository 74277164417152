import React from 'react'
import styled from 'styled-components'
import Altschnee from './images/weak.svg'
import Gleitschnee from './images/gliding_snow.svg'
import Guenstig from './images/favourable.svg'
import Nassschnee from './images/wet_snow.svg'
import Neuschnee from './images/new_snow.svg'
import Triebschnee from './images/wind.svg'
import PropTypes from 'prop-types'

import Title from '../../../../../../../../../../../../../../../../components/Title'

const Wrapper = styled.div``

const ImageContent = styled.div`
  margin-right: 26px;
`

const Image = styled.img`
  border: 2px solid rgb(0, 0, 0);
  margin-left: auto;
  margin-right: auto;
  border-radius: 13px;
  min-width: 70px;
  max-width: 70px;
  @media (max-width: ${(props) => props.theme.tablet.breakPoint + 'px'}) {
    height: 100%;
    width: 100%;
    border-radius: 13px;
  }
  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    height: 100%;
    width: 100%;
    border-radius: 13px;
  }
`

const StyledTitle = styled(Title)`
  font-size: ${(props) => props.theme.fontSize.text};
  @media (max-width: ${(props) => props.theme.tablet.breakPoint + 'px'}) {
    font-size: ${(props) => props.theme.tablet.fontSize.text};
  }
  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    font-size: ${(props) => props.theme.mobile.fontSize.text};
  }
  color: ${(props) => props.theme.color.stol};
  margin: 0;
`

const ImageMap = (code) => {
  const map = {
    new_snow: Neuschnee,
    drifting_snow: Triebschnee,
    old_snow: Altschnee,
    wet_snow: Nassschnee,
    gliding_snow: Gleitschnee,
    favourable: Guenstig,
  }
  return map[code]
}

const SnowProblem = ({ snowStyle, ...props }) => {
  return (
    <Wrapper {...props}>
      <Image src={ImageMap(snowStyle.code)} />
    </Wrapper>
  )
}

SnowProblem.propTypes = {
  snowStyle: PropTypes.shape({
    code: PropTypes.string,
    description: PropTypes.string,
  }),
}

SnowProblem.defaultProps = {
  snowStyle: {
    code: 'new_snow',
    description: 'Neuschnee',
  },
}

export default SnowProblem
