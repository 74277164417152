import styled from 'styled-components'
import Title from '../../../../../../components/Title'

const TitleTextForecast = styled(Title)`
  font-size: ${(props) => props.theme.fontSize.title3};
  @media (max-width: ${(props) => props.theme.tablet.breakPoint + 'px'}) {
    font-size: ${(props) => props.theme.tablet.fontSize.title3};
  }
  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    font-size: ${(props) => props.theme.mobile.fontSize.title3};
  }
  margin-bottom: 0;
  margin-top: 0.25em;
`

TitleTextForecast.defaultProps = {
  tag: 'h3',
}

export default TitleTextForecast
