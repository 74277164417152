import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Text from '../../../../../../../../../../../../../../../../components/Text'

import ReactTooltip from 'react-tooltip'
import MountainDown from './images/MountainDown'
import MountainUp from './images/MountainUp'
import SuperMountain from './images/Schneegrenze.svg'
import SuperMountainDown from './images/Schneegrenzeunten.svg'
import Full from './images/SchneegrenzeFull.svg'

import SVG from 'react-inlinesvg'

const Icon = () => <SVG src="./images/MountainDown.svg" />

const Wrapper = styled.div`
  position: relative;
  text-align: center;
  color: white;
`

const StyledMountainUp = styled(MountainUp)`
  display: block;
  height: 100%;
  width: 100%;
`

const NiceTitle = styled(Text)`
  position: relative;
  bottom: -67px;
  @media (max-width: ${(props) => props.theme.tablet.breakPoint + 'px'}) {
    bottom: -70px;
  }
  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    bottom: -70px;
  }
`

const StyledMountainDown = styled(MountainDown)`
  display: block;
  height: 100%;
  width: 100%;
`

const SnowFallHeightAbove = styled(Text)``
const SnowFallHeightBelow = styled(Text)``

/*TODO: get the right images for the snowFall*/
const SnowFall = ({ snowFall, ...props }) => (
  <Wrapper {...props}>
    {snowFall.hi_lw == 0 && <img src={SuperMountainDown} />}

    {snowFall.hi_lw == 1 && <img src={SuperMountain} />}
    {snowFall.hi_lw == -1 && <img src={Full} />}
  </Wrapper>
)
SnowFall.propTypes = {
  snowFall: PropTypes.shape({
    src: PropTypes.string,
    altitude: PropTypes.string,
    hi_lw: PropTypes.number,
  }),
}
SnowFall.defaultProps = {
  snowFall: {
    altitude: '2600',
    hi_lw: 0,
  },
}
export default SnowFall
