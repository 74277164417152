import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const style1 = {
  fillRule: 'evenodd',
  clipRule: 'evenodd',
  strokeLinejoin: 'round',
  strokeMiterlimit: '2',
}

const style2 = {
  fontFamily: 'Arial-BoldMT',
  fontWeight: '700',
  fontSize: '145.29px',
}
const MountainDown = ({ altitude, ...props }) => (
  <svg width="90%" height="90%" viewBox="0 0 1000 1000" version="1.1" style={style1}>
    <g transform="matrix(1,0,0,1,-3120,0)">
      <rect id="Artboard3" x="3020" y="0" width="850" height="500" style={{ fill: 'none' }} />
      <g id="Artboard31">
        <g transform="matrix(0.831007,0,0,0.831007,2722.23,43.7094)">
          <g transform="matrix(1.46857,0,0,1,-104.501,0)">
            <path d="M724.855,289.145L1054.75,809.018L394.964,809.018L724.855,289.145Z" style={{ fill: 'white' }} />
          </g>
          <path d="M811.508,450.635L895.234,441.375L960,470.961L1031.4,431.665L1111.53,454.815L960,289.145L811.508,450.635Z" />
          <g transform="matrix(1.46857,0,0,1,-104.501,0)">
            <path d="M1058.68,809.752L1057.8,812.751L391.912,812.751L391.024,809.752L724.854,283.671L1058.68,809.752ZM400.806,805.286L1048.9,805.286L724.854,294.62L400.806,805.286Z" />
          </g>
          <g transform="matrix(0.816811,0,0,1,153.193,196.796)">
            <rect x="1140.92" y="222.998" width="384.973" height="9.617" />
          </g>
          <g transform="matrix(0.64051,0,0,0.64051,331.54,216.69)">
            <text x="1200.68px" y="307.799px" style={style2}>
              {altitude}
            </text>
          </g>
          <g transform="matrix(0.983155,0,0,-1.44623,81.6828,789.415)">
            <path d="M1342.37,245.402L1306.98,208.669L1271.6,245.402L1342.37,245.402Z" />
          </g>
        </g>
      </g>
    </g>
  </svg>
)
MountainDown.propTypes = {}
MountainDown.defaultProps = {}
export default MountainDown
