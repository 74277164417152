import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import SnowDangerPattern from './components/SnowDangerPattern'
import SnowTendency from './components/SnowTendency'
import DangerSnow from '../DangerLevel/components/DangerField/components/DangerSnow'
import Text from '../../../../../../../../../../components/Text'
import Title from '../../../../../../../../../../components/Title'

const Wrapper = styled.table``

const SuperDiv = styled.div`
  display: flex;
  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    display: blocK;
  }
`

const TextDiv = styled.div`
  width: 65%;

  margin-left: auto;

  margin-right: auto;

  @media (max-width: ${(props) => props.theme.tablet.breakPoint + 'px'}) {
    width: 60%;
  }
  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    width: 100%;
  }
`

const StyledTitle = styled(Title)``
const SnowAdvice = styled(Text)``
const SnowReport = ({ data, ...props }) => {

  let myAdvice= data.snowAdvice;
  let myAdviceFormat = myAdvice.split("<br/>");

  return(
  <Wrapper {...props}>
    <SuperDiv>
      <DangerSnow data={data.dangerSnow}></DangerSnow>

      <TextDiv>
        <StyledTitle>Schneedecke</StyledTitle>

        <SnowAdvice >{myAdviceFormat}</SnowAdvice>
      </TextDiv>
    </SuperDiv>
  </Wrapper>
)}
SnowReport.propTypes = {
  data: PropTypes.shape({
    snowDangerPattern: SnowDangerPattern.propTypes,
    snowAdvice: PropTypes.string,
    snowTendency: SnowTendency.propTypes,
    dangerSnow: DangerSnow.propTypes,
  }),
}
SnowReport.defaultProps = {
  data: {
    snowDangerPattern: SnowDangerPattern.defaultProps.data,
    snowAdvice:
      'Der Triebschnee liegt verbreitet auf weichen Schichten. ' +
      'Die Schneedecke bleibt in tiefen und mittleren Lagen feucht. ' +
      'Trockene und feuchte Lawinen können vereinzelt in tiefe Schichten durchreissen und mittlere Grösse erreichen.',
    snowTendency: SnowTendency.defaultProps.data,
    dangerSnow: DangerSnow.defaultProps.data,
  },
}
export default SnowReport
