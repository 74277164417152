import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import translate from '../../../../../../../../../../modules/translate'
import DangerExpositionRose from './images/DangerExpositionRose.js'
import Text from '../../../../../../../../../../components/Text'

const Wrapper = styled.div`
  position: relative;
  display: inline-block;
`

const North = styled(Text)`
  position: absolute;
  font-weight: bold;
  top: -19px;
  left: 27px;
`
const Est = styled(Text)`
  position: absolute;
  font-weight: bold;
  top: 21px;
  right: -14px;
`
const South = styled(Text)`
  position: absolute;
  font-weight: bold;
  bottom: -18px;
  left: 27px;
`
const West = styled(Text)`
  position: absolute;
  font-weight: bold;
  top: 21px;
  left: -16px;
`

let StyledDangerExpositionRose = styled(DangerExpositionRose)`
  display: block;
  width: 64px;
  fill: gray;

  .n {
    fill: ${(props) => (props.roseIsDark.n ? props.theme.color.textPrimary : props.theme.color.textTertiary)};
  }
  .nw {
    fill: ${(props) => (props.roseIsDark.nw ? props.theme.color.textPrimary : props.theme.color.textTertiary)};
  }
  .ne {
    fill: ${(props) => (props.roseIsDark.ne ? props.theme.color.textPrimary : props.theme.color.textTertiary)};
  }
  .e {
    fill: ${(props) => (props.roseIsDark.e ? props.theme.color.textPrimary : props.theme.color.textTertiary)};
  }
  .se {
    fill: ${(props) => (props.roseIsDark.se ? props.theme.color.textPrimary : props.theme.color.textTertiary)};
  }
  .sw {
    fill: ${(props) => (props.roseIsDark.sw ? props.theme.color.textPrimary : props.theme.color.textTertiary)};
  }
  .s {
    fill: ${(props) => (props.roseIsDark.s ? props.theme.color.textPrimary : props.theme.color.textTertiary)};
  }
  .w {
    fill: ${(props) => (props.roseIsDark.w ? props.theme.color.textPrimary : props.theme.color.textTertiary)};
  }
`

const ExpositionRose = ({ aspects = [], ...props }) => {
  let roseIsDark = {}
  if (aspects && aspects.length > 0) {
    aspects.forEach(function (aspect) {
      roseIsDark[aspect] = true
    })
  }
  return (
    <Wrapper {...props}>
      <StyledDangerExpositionRose roseIsDark={roseIsDark} />
      <North>{translate('short_north')}</North>
      <Est>{translate('short_est')}</Est>
      <South>{translate('short_south')}</South>
      <West>{translate('short_west')}</West>
    </Wrapper>
  )
}

ExpositionRose.propTypes = {
  aspects: PropTypes.array,
}

export default ExpositionRose
