import React from 'react'
import styled from 'styled-components'
import TitleDescriptionForecast from '../../../../components/TitleDescriptionForecast'
import Altschnee from './images/weak_persistent_layer.svg'
import Gleitschnee from './images/gliding_snow.svg'
import Guenstig from './images/favourable_situation.svg'
import Nassschnee from './images/wet_snow.svg'
import Neuschnee from './images/new_snow.svg'
import Triebschnee from './images/wind_drifted_snow.svg'
import PropTypes from 'prop-types'
import translate from '../../../../../../../../modules/translate'
import Title from '../../../../../../../../components/Title'

const Wrapper = styled.div``

const StyledTitleDescriptionForecast = styled(TitleDescriptionForecast)`
  margin-bottom: 39px;
`

const ImageWrapper = styled.div`
  display: flex;
`

const ImageContent = styled.div`
  margin-right: 26px;
`

const Image = styled.img`
  width: 100%;
  max-width: 80px;
  margin-bottom: 37px;
`

const StyledTitle = styled(Title)`
  font-size: ${(props) => props.theme.fontSize.text};
  @media (max-width: ${(props) => props.theme.tablet.breakPoint + 'px'}) {
    font-size: ${(props) => props.theme.tablet.fontSize.text};
  }
  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    font-size: ${(props) => props.theme.mobile.fontSize.text};
  }
  color: ${(props) => props.theme.color.stol};
  margin: 0;
`

const ImageMap = (code) => {
  const map = {
    new_snow: Neuschnee,
    wind_drifted_snow: Triebschnee,
    weak_persistent_layer: Altschnee,
    wet_snow: Nassschnee,
    gliding_snow: Gleitschnee,
    favourable_situation: Guenstig,
  }
  return map[code]
}

const SnowProblem = ({ data, ...props }) => {
  return (
    <Wrapper {...props}>
      <StyledTitleDescriptionForecast>
        <strong>{translate('weather_avalanche_problems')}</strong>
      </StyledTitleDescriptionForecast>
      <ImageWrapper>
        {data.map((problem, index) => {
          const ImageSrc = ImageMap(problem.code)
          return (
            <ImageContent key={'problem' + index}>
              <Image src={ImageSrc} />
              <StyledTitle>{problem.description}</StyledTitle>
            </ImageContent>
          )
        })}
      </ImageWrapper>
    </Wrapper>
  )
}

SnowProblem.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      code: PropTypes.string,
      description: PropTypes.string,
    }),
  ),
}

export default SnowProblem
export { ImageMap }
