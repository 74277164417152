import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import DangerLevel from './components/DangerLevel'
import SnowReport from './components/SnowReport'

const Wrapper = styled.div`
  border-radius: 12px;
`
const ReportDiv = styled.div``

const StyledDangerLevel = styled(DangerLevel)`
  border-left: ${(props) => props.borderLeft};
  background-color: ${(props) => props.background};

  padding: 10px;
`

const StyledSnowReport = styled(SnowReport)`
  border-left: 5px solid rgb(0, 150, 210);
  background-color: rgb(0, 150, 210, 0.1);
  padding: 10px;
`
const DangerReport = ({ data, ...props }) => {
  return (
    <Wrapper {...props}>
      <ReportDiv>
        {data && data.dangerLevelReport.description.danger === 1 && (
          <StyledDangerLevel
            borderLeft="5px solid rgb(186, 211, 124)"
            background="rgb(186, 211, 124,0.1)"
            data={data.dangerLevelReport}
          />
        )}
        {data && data.dangerLevelReport.description.danger === 2 && (
          <StyledDangerLevel
            borderLeft="5px solid rgb(255, 255, 0)"
            background="rgb(255, 230, 0,0.2)"
            data={data.dangerLevelReport}
          />
        )}
        {data && data.dangerLevelReport.description.danger === 3 && (
          <StyledDangerLevel
            borderLeft="5px solid rgb(255, 130, 0)"
            background="rgb(255,130,0,0.1)"
            data={data.dangerLevelReport}
          />
        )}
        {data && data.dangerLevelReport.description.danger === 4 && (
          <StyledDangerLevel
            borderLeft="5px solid rgb(254, 0, 0)"
            background="rgb(254, 0, 0,0.1)"
            data={data.dangerLevelReport}
          />
        )}
        {data && data.dangerLevelReport.description.danger === 5 && (
          <StyledDangerLevel
            borderLeft="5px solid rgb(170, 0, 0);"
            background="rgb(170, 0, 0,0.2)"
            data={data.dangerLevelReport}
          />
        )}

        <StyledSnowReport data={data && data.snowReport} />
      </ReportDiv>
    </Wrapper>
  )
}
DangerReport.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      DangerLevel: DangerLevel.propTypes,
      snowReport: SnowReport.propTypes,
    }),
  ),
}

export default DangerReport
