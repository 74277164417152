import React from 'react'

const xmlParameter = {
  'xmlns:xlink': 'http://www.w3.org/1999/xlink',
  'xml:space': 'preserve',
}

const SvgDangerExpositionRose = props => (
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    viewBox="0 0 66.1 66.1"
    {...xmlParameter}
    {...props}
  >
    <polygon className="w" points="0.6,21.2 0.6,44.9 29.1,33.1" />
    <polygon className="sw" points="1.7,47.7 18.5,64.4 30.3,35.8" />
    <polygon className="s" points="21.2,65.6 44.9,65.6 33.1,37" />
    <polygon className="se" points="47.7,64.4 64.4,47.7 35.8,35.8" />
    <polygon className="e" points="65.6,44.9 65.6,21.2 37,33.1" />
    <polygon className="ne" points="64.4,18.5 47.7,1.7 35.8,30.3" />
    <polygon className="n" points="44.9,0.6 21.2,0.6 33.1,29.1" />
    <polygon className="nw" points="18.5,1.7 1.7,18.5 30.3,30.3" />
  </svg>
)

export default SvgDangerExpositionRose
