import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import TitleDescriptionForecast from './components/TitleDescriptionForecast'
import TitleForecast from './components/TitleForecast'
import TitleTextForecast from './components/TitleTextForecast'
import { NoTruncateTextArticle } from '../../../../components/TextArticle'
import ContainerArticle from '../../../../components/ContainerArticle'
import { htmlParser } from '../../../../modules/parse'

const Wrapper = styled.div`
  padding-top: ${(props) => props.theme.sizes.gapVertical};
  padding-bottom: 3em;
  @media (max-width: ${(props) => props.theme.tablet.breakPoint + 'px'}) {
    padding-top: ${(props) => props.theme.tablet.sizes.gapVertical};
  }
  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    padding-top: ${(props) => props.theme.mobile.sizes.gapVertical};
  }
`

const Forecast = ({ subtitle, title, textBlocks, elementBelowTitle, elementEnd, ...props }) => (
  <Wrapper {...props}>
    <ContainerArticle>
      {subtitle && <TitleDescriptionForecast>{subtitle}</TitleDescriptionForecast>}
      {elementBelowTitle}
      {title && <TitleForecast>{title}</TitleForecast>}
      {textBlocks.map((textBlock) => (
        <div key={textBlock.title + textBlock.text}>
          {textBlock.title && textBlock.text && <TitleTextForecast>{textBlock.title}</TitleTextForecast>}
          {textBlock.text && <NoTruncateTextArticle tag="div">{htmlParser(textBlock.text)}</NoTruncateTextArticle>}
        </div>
      ))}
    </ContainerArticle>
    {elementEnd}
  </Wrapper>
)

Forecast.propTypes = {
  subtitle: PropTypes.node,
  title: PropTypes.string,
  textBlocks: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      text: PropTypes.string,
    }),
  ),
  elementBelowTitle: PropTypes.node,
  elementEnd: PropTypes.node,
}

Forecast.defaultProps = {
  textBlocks: [],
}

export default Forecast
export { Wrapper as Forecast0Wrapper, ContainerArticle as Forecast0ContainerArticle }
