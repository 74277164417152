import React from 'react'
import Text from '../../../../components/Text'
import styled from 'styled-components'
import translate from '../../../../modules/translate'

const ContainerTextCopyRight = styled.div`
  padding-right: ${(props) => props.theme.sizes.gapEdge};
  padding-left: ${(props) => props.theme.sizes.gapEdge};
  @media (max-width: ${(props) => props.theme.tablet.breakPoint + 'px'}) {
    padding-right: ${(props) => props.theme.tablet.sizes.gapEdge};
    padding-left: ${(props) => props.theme.tablet.sizes.gapEdge};
  }
  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    padding-right: ${(props) => props.theme.mobile.sizes.gapEdge};
    padding-left: ${(props) => props.theme.mobile.sizes.gapEdge};
  }
`

const StyledText = styled(Text)`
  padding-top: 0.5em;
  padding-right: 3em;
  text-align: right;
  margin-top: 1.5em;
  padding-top: 1.5em;
  margin-bottom: 3em;
  border-top: ${(props) => props.theme.color.textTertiary} 1px solid;

  a {
    white-space: nowrap;
  }
`

const TextCopyrightWeather = ({ ...props }) => (
  <ContainerTextCopyRight>
    <StyledText {...props}>{translate('weather_copyright')}</StyledText>
  </ContainerTextCopyRight>
)

export default TextCopyrightWeather
