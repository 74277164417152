import React from 'react'
import styled, { css } from 'styled-components'
import PropTypes from 'prop-types'
import LowImage from './images/1.svg'
import ModerateImage from './images/2.svg'
import ConsiderableImage from './images/3.svg'
import HighImage from './images/4_5.svg'
import ExtremImage from './images/4_5.svg'
import Text from '../../../../../../../../components/Text'
import styledContainerQuery from 'styled-container-query'
import translate from '../../../../../../../../modules/translate'

const Image = styled.img`
  position: absolute;
  left: 0;
  top: -4px;
  height: 29px;
`

const DangerLevel = styled(({ loading, level, gray, ...props }) => <Text {...props} />)`
  position: relative;
  flex-grow: 1;
  padding-left: 32px;
  font-size: ${(props) => props.theme.fontSize.small};
  @media (max-width: ${(props) => props.theme.tablet.breakPoint + 'px'}) {
    font-size: ${(props) => props.theme.tablet.fontSize.small};
  }
  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    font-size: ${(props) => props.theme.mobile.fontSize.small};
  }
  background-color: ${(props) => props.level};
  height: 1.4em;
  ${(props) =>
    props.loading &&
    css`
      background-color: ${(props) => props.gray};
    `};

  margin-left: auto;
  margin-right: auto;
`

const Wrapper = styledContainerQuery.div`
  display: flex;
  flex-wrap: wrap;
  text-align: center;
  margin-top: 15px;

  &:container(max-width: 530px){
    ${DangerLevel} {
      flex-grow: 0;
      width: 50%;
      margin-bottom: 15px;
      padding-left: 0;
    }
  }
`

const grayMap = (level) => {
  return {
    low: '#e1e1e1',
    moderate: '#cdcdcd',
    considerable: '#b9b9b9',
    high: '#afafaf',
    extrem: '#a6a6a6',
  }[level]
}

const colorMap = (level) => {
  return {
    low: '#bad37c',
    moderate: '#ffff00',
    considerable: '#fe9800',
    high: '#fe0000',
    extrem: '#d60000',
  }[level]
}

const ScaleMapForecastAvalanche = ({ loading, ...props }) => {
  return (
    <Wrapper loading={loading} {...props}>
      <DangerLevel level={colorMap('low')} loading={loading} gray={grayMap('low')}>
        {!loading && translate('weather_avalanche_scale_one')}
        {!loading && <Image src={LowImage} />}
      </DangerLevel>
      <DangerLevel level={colorMap('moderate')} loading={loading} gray={grayMap('moderate')}>
        {!loading && translate('weather_avalanche_scale_two')}
        {!loading && <Image src={ModerateImage} />}
      </DangerLevel>
      <DangerLevel level={colorMap('considerable')} loading={loading} gray={grayMap('considerable')}>
        {!loading && translate('weather_avalanche_scale_three')}
        {!loading && <Image src={ConsiderableImage} />}
      </DangerLevel>
      <DangerLevel level={colorMap('high')} loading={loading} gray={grayMap('high')}>
        {!loading && translate('weather_avalanche_scale_four')}
        {!loading && <Image src={HighImage} />}
      </DangerLevel>
      <DangerLevel level={colorMap('extrem')} loading={loading} gray={grayMap('extrem')}>
        {!loading && translate('weather_avalanche_scale_fife')}
        {!loading && <Image src={ExtremImage} />}
      </DangerLevel>
    </Wrapper>
  )
}

ScaleMapForecastAvalanche.propTypes = {
  loading: PropTypes.bool,
}

ScaleMapForecastAvalanche.defaultProps = {
  loading: false,
}

export default ScaleMapForecastAvalanche
