import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import ErrorBoundary from '../../../../../Error/widgets/ErrorBoundary/index'
import ForecastAvalanche from '../../../../components/Forecast/views/ForecastAvalanche/index'
import TextCopyrightWeather from '../../../../components/TextCopyrightWeather/index'
import translate from '../../../../../../modules/translate/index'
import ForecastAvalancheSkeleton from '../../../../components/Forecast/views/ForecastAvalanche/views/ForecastAvalancheSkeleton'
import ContainerAd from '../../../../../../components/ContainerAd/index'
import MessageError from '../../../../../Error/screens/MessageError/index'

const Wrapper = styled.div``

const DisplayAvalanche = ({ loading, failed, data, ...props }) => {
  const errorOptions = {
    component: translate('weather_avalanche_error'),
    link: '/',
    linkText: translate('error_try_homepage'),
    logError: 'Avalanche sub-screen display',
  }

  return (
    <Wrapper {...props}>
      <ErrorBoundary error={failed} {...errorOptions}>
        {loading ? (
          <ForecastAvalancheSkeleton />
        ) : data ? (
          <ForecastAvalanche data={data} adComponent={<ContainerAd adIds={['S7']} />} />
        ) : (
          <MessageError error={failed} logInfo={translate('dev_errorlog_no_data')} {...errorOptions} />
        )}
      </ErrorBoundary>
      <TextCopyrightWeather />
    </Wrapper>
  )
}

DisplayAvalanche.propTypes = {
  loading: PropTypes.bool,
  failed: PropTypes.bool,
  data: PropTypes.shape({ ...ForecastAvalanche.propTypes.data }),
}

DisplayAvalanche.defaultProps = {
  loading: false,
  failed: false,
  data: {},
}

export default DisplayAvalanche
