import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import ExpositionRose from './components/ExpositionRose'
import SnowProblem from './components/SnowProblem'
import Title from '../../../../../../../../../../../../../../components/Title'
import Text from '../../../../../../../../../../../../../../components/Text'
import SnowFall from './components/SnowFall'
import ReactTooltip from 'react-tooltip'
import SuperMountainDown from './components/SnowFall/images/Schneegrenzeunten.svg'

const Wrapper = styled.div`
  width: 27%;

  @media (max-width: ${(props) => props.theme.tablet.breakPoint + 'px'}) {
    width: 35%;
  }
  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    width: 100%;
  }
`
const SnowDiv = styled.div`
  display: flex;
  position: relative;
`

const StyledSnowProblem = styled(SnowProblem)`
  margin-right: 10px;
  border-radius: 15px;
  margin-bottom: auto;

  margin-top: auto;
  max-width: 80px;
  @media (max-width: ${(props) => props.theme.tablet.breakPoint + 'px'}) {
    max-width: 70px;
  }
  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    max-width: 70px;
    margin-left: auto;
    margin-right: auto;
  }
`
const NiceTitle = styled(Text)`
  font-size: ${(props) => props.theme.fontSize.text};
  @media (max-width: ${(props) => props.theme.tablet.breakPoint + 'px'}) {
    font-size: ${(props) => props.theme.tablet.fontSize.text};
  }
  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    font-size: ${(props) => props.theme.mobile.fontSize.text};
  }
  color: ${(props) => props.theme.color.stol};
  margin: 0;
  font-weight: bold;
  color: rgb(0, 150, 210);
`

const StyledPTagAbove = styled(Text)`
  position: absolute;
  left: 77px;

  bottom: 44px;
`
const StyledPTagBelow = styled(Text)`
  position: absolute;
  left: 103px;

  bottom: 20px;
`
/*/*/
const StyledExpositionRose = styled(ExpositionRose)`
  margin-left: auto;
  margin-right: auto;
  margin-bottom: auto;

  margin-top: auto;
  width: 100px;
  @media (max-width: ${(props) => props.theme.tablet.breakPoint + 'px'}) {
    width: 80px;
  }
  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    width: 80px;
  }
`
const StyledSnowFall = styled(SnowFall)`
  margin-left: auto;
  margin-right: auto;

  margin-top: auto;

  margin-bottom: auto;
  width: 90px;
  @media (max-width: ${(props) => props.theme.tablet.breakPoint + 'px'}) {
    width: 85px;
  }
  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    width: 85px;
  }
`
const SnowDangerDiv = styled.div`
  border-left: 5px solid rgb(0, 150, 210);

  background-color: rgb(0, 150, 210, 0.1);
  margin-bottom: 5px;
  border-radius: 10px;
  padding: 15px;
  @media (max-width: ${(props) => props.theme.tablet.breakPoint + 'px'}) {
    padding: 5px;
  }
`

const StyledTooltip = styled(ReactTooltip)`
  font-family: ${(props) => props.theme.default.font.primary};
`
/*
 */

const DangerSnow = ({ data, ...props }) => (
  <Wrapper {...props}>
    {data.map((snowReport) => {
      let description = snowReport.snowStyle.description
      if (description.localeCompare('old_snow') == 0) {
        description = 'Altschnee'
      }
      return (
        <SnowDangerDiv>
          {snowReport.snowFall.hi_lw == 0 && (
            <NiceTitle>
              {description} - unter {snowReport.snowFall.altitude}
            </NiceTitle>
          )}
          {snowReport.snowFall.hi_lw == 1 && (
            <NiceTitle>
              {description} - über {snowReport.snowFall.altitude}
            </NiceTitle>
          )}
          {snowReport.snowFall.hi_lw == -1 && <NiceTitle>{description}</NiceTitle>}

          <SnowDiv>
            <StyledSnowProblem data-tip={'Lawinenproblem: ' + description} snowStyle={snowReport.snowStyle} />
            <StyledTooltip />

            <StyledSnowFall data-tip="Höhe in der das Lawinenproblem vorherrscht" snowFall={snowReport.snowFall} />
            <StyledTooltip />

            <StyledExpositionRose
              data-tip="Exposition in der das Lawinenproblem vorherrscht"
              aspects={snowReport.aspects}
            />
            <StyledTooltip />
          </SnowDiv>
        </SnowDangerDiv>
      )
    })}
  </Wrapper>
)
DangerSnow.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      aspects: ExpositionRose.propTypes,
      snowStyle: SnowProblem.propTypes,
      snowFall: SnowFall.propTypes,
    }),
  ),
}
DangerSnow.defaultProps = {
  data: [
    {
      aspects: ['n'],
      snowStyle: {
        code: 'new_snow',
        description: 'Neuschnee',
      },
      snowFall: {
        altitude: '2600',
        hi_lw: 0,
      },
    },
    {
      aspects: ['ne', 'e'],
      snowStyle: {
        code: 'weak',
        description: 'Altschnee',
      },
      snowFall: {
        altitude: '2400',
        hi_lw: 0,
      },
    },
  ],
}
export default DangerSnow
