import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
const style1 = {
  fillRule: 'evenodd',
  clipRule: 'evenodd',
  strokeLinejoin: 'round',
  strokeMiterlimit: '2',
}

const style2 = {
  fontFamily: 'Arial-BoldMT',
  fontWeight: '700',
  fontSize: '145.29px',
}
const Wrapper = styled.div``
const MountainUp = ({ altitude, ...props }) => (
  <svg width="90%" height="90%" viewBox="0 0 1000 1000" version="1.1" style={style1}>
    <g transform="matrix(1,0,0,1,-1975,0)">
      <g id="Artboard2" transform="matrix(0.520833,0,0,0.925926,943.958,0)">
        <rect x="1970" y="0" width="1920" height="1080" style={{ fill: 'none' }} />
        <g transform="matrix(1.59553,0,0,0.897487,1222.86,47.2061)">
          <g transform="matrix(1.46857,0,0,1,-104.501,0)">
            <path d="M724.855,289.145L1054.75,809.018L394.964,809.018L724.855,289.145Z" />
          </g>
          <path
            d="M960,289.145L670.467,601.028L714.594,601.028L770.458,608.082L889.061,612.896L960,632.847L1040.6,617.381L1151.97,617.381L1207.21,601.028L1267.76,617.381L960,289.145Z"
            style={{ fill: 'white' }}
          />
          <g transform="matrix(1.46857,0,0,1,-104.501,0)">
            <path d="M1058.68,809.752L1057.8,812.751L391.912,812.751L391.024,809.752L724.855,283.671L1058.68,809.752ZM400.806,805.286L1048.9,805.286L724.855,294.62L400.806,805.286Z" />
          </g>
          <g transform="matrix(1.02587,0,0,1,99.3762,393.081)">
            <rect x="1142.12" y="222.994" width="382.583" height="9.627" />
          </g>
          <g transform="matrix(0.64051,0,0,0.64051,595.578,501.216)">
            <text id="textPlace" x="1180.68px" y="310.799px" style={style2}>
              {altitude}
            </text>
          </g>
          <g transform="matrix(1.02518,0,0,1.50805,288.202,239.32)">
            <path d="M1342.37,245.402L1306.98,208.669L1271.6,245.402L1342.37,245.402Z" />
          </g>
        </g>
      </g>
    </g>
  </svg>
)
MountainUp.propTypes = {}
MountainUp.defaultProps = {}
export default MountainUp
